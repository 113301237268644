import VueCookies from "vue-cookies";
import {validateTokeOnLogin, decodeToken, getAccessToken} from "@/utils/auth";
import JWT from "jsonwebtoken";
import {SSO_PUBLIC_CERT} from "@/utils/constants";
import {Chrome} from "vue-color";
import Vue from "vue";

export default {
    name: "login-callback",
    components: {},
    props: [],
    watch: {},
    data() {
        return {
            timeskew: true,
        };
    },
    computed: {},
    async mounted() {
        /*
        This is the entry point after a successful login by user.
        1. We pull out the token from the url, redirected by the sso app(Miniornage).
        2. Validate token by auth server(neotas iam) & locally using miniorange cert.
        3. If token is valid, log user in or else log user out.
        */
        let payload = {
            callback_url: process.env.VUE_APP_CALLBACK_URL,
        };
        if (this.$route.query.code)
            payload = {
                ...payload,
                code: this.$route.query.code,
            };
        else if (this.$route.query.token)
            payload = {
                ...payload,
                token: this.$route.query.token,
            };
        let token = null;
        if (!this.$route.query.id_token) {
            try {
                const result = await getAccessToken(payload);
                token = result.data.access_token;
                if (result.data.meta_data && result.data.meta_data.case_id) {
                    let next_url = `research/${result.data.meta_data.case_id}`;
                    if (result.data.meta_data.page) {
                        next_url = next_url + `/${result.data.meta_data.page}`;
                    }
                    VueCookies.set("next-url", next_url, null, null, null, "true", "none");
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            token = this.$route.query.id_token;
        }
        if (token) {
            let decodedToken = decodeToken(token);
            let data = {decodedToken: decodedToken, token: token};
            this.$store.dispatch("setLoginSession", data);
            this.sendToken(token);
            this.routeToNextUrl();
        } else {
            this.$store.dispatch("logoutUser");
        }
    },
    methods: {
        /**
         * Sends access token to neotas extension
         * @param {string} token access token for extension login.
         */
        sendToken(accessToken) {
            // try {
            // chrome.runtime.sendMessage(
            //     this.$store.state.research.extensionID,
            //     {
            //         msg: "sendAccessToken",
            //         token: accessToken
            //     }
            // );
            // }
            // catch (err) {
            //     return
            // }
            return;
        },
        routeToNextUrl() {
            const nextURL = VueCookies.get("next-url");
            if (nextURL) this.$router.push(nextURL).catch(() => {});
            else this.$router.push({name: "View Cases"}).catch(() => {});
        },
    },
};
